.footer-container{
  background-color: #e5e5e5;
  margin: 0px;
 
}

.footer-list{
  font-size: 0.8rem;
  text-decoration: none;
  list-style-type: none;
  padding: 0px;
}

.footer-list > li {
  padding-bottom:0.2rem;

}
.footer-list > li > a{
    text-decoration: none;
    color: #1d1f48;
   

}
.ContactBtn {
    position: relative;
    overflow: hidden;
    /* Ensures the ripple stays within button bounds */
}

.ripple {
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    pointer-events: none;
    /* Ensures the ripple doesn't block clicks */
    transform: translate(-50%, -50%);
    animation: ripple-effect 0.7s ease infinite;
}

.ContactBtn:hover {
    transform: translateY(1px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: 400ms;
}

.ContactBtn:active {
    transform: translateY(-1px);
    scale: 0.98;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    transition: 100ms;
}


@keyframes ripple-effect {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0.5;
    }

    100% {
        width: 500px;
        height: 500px;
        opacity: 0;
    }
}
:root{
  --Mindox-blue:#1d1f48,
  
}
body {
  font-family: 'Infra', sans-serif !important;
  color:#1d1f48;
  background-color: #fffdfd;
  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.whatsapp {
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 3px 4px 3px #999;
	right: 15px;
	z-index: 100;
	cursor: pointer;
}

.whatsapp:hover {
	color: #fff;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Infra Black */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraBlack.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraBlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

/* Infra Bold */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraBoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

/* Infra Extra Bold */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

/* Infra Extra Light */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

/* Infra Hairline */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraHairline.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraHairlineItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

/* Infra Light */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraLightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

/* Infra Medium */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraMediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

/* Infra Regular */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraRegular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraRegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

/* Infra Semi Bold */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraSemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraSemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

/* Infra Thin */
@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraThin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Infra';
  src: url('./fonts/InfraThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

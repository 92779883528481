

.navbar-root-element {

    position: sticky;
    top: 0;
    
    background-color: #fff;
    z-index: 11;
    
}
.nav-container{
    width: 90vw;
}
@media (min-width: 983px) {
    .navbar-root-element {
        height: 8vh
    }
}

.dropdown-menu > li > a {
    color: #131647;
    text-decoration: none;
}

.dropdown-menu > li > button {
    color: #131647;
    text-decoration: none;
}

.dropdown-menu > li:hover {
    background-color: #131647;  
    animation: fadeIn 0.3s ease;
}


.dropdown-menu > li:hover > a {
    color: #ffffff !important;  
    animation: fadeIn 0.3s ease;
}



.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    animation: fadeIn 0.3s ease;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.nav-item.dropdown .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
}

.dropdown:hover .dropdown-arrow {
    transform: rotate(180deg);
}

.dropdown-arrow {
    transition: transform 0.3s ease;
}

.nav-link.dropdown-toggle::after {
    display: none;
    /* Removes Bootstrap's default icon */
}